<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-28 15:59:38
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-29 14:19:57
-->
<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
<!--          <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
<!--            <a-form-model-item label="门店名称">-->
<!--              <a-input v-model="searchData.storeName" placeholder="门店名称"></a-input>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
          <a-col :md="8" :xl="6" :xxl="8" :sm="12">
            <a-form-model-item label="员工名称">
              <a-input v-model="searchData.storeStaffName" placeholder="员工名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="门店">
              <a-select placeholder="请选择门店" v-model="searchData.storeId" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in storeData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="员工">
              <a-select placeholder="请选择员工" v-model="searchData.storeStaffId">
                <a-select-option :value="item.id" v-for="(item, index) in managerData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <!-- <a-button type="primary" @click="toHandler('add')">申请配额</a-button> -->
        <a-button v-if="isUpdate" type="primary" @click="toHandler('staff_allocation')">调整配额</a-button>
        <!-- <a-button type="primary" @click="toHandler('del')">删除</a-button> -->
        <!-- <a-button type="primary" @click="toHandler('submit_audio')">提交审核</a-button> -->
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="status" slot-scope="text">
          <span> {{ text | status }} </span>
        </span>
        <span slot="quotaStatus" slot-scope="text">
          <span v-if="text == 1">未过期</span>
          <span v-if="text == 2">已过期</span>
        </span>

        <span slot="startingTime" slot-scope="text, row"> {{ row.startingTime }}~{{ row.expirDate }} </span>
        <span slot="surplusPreOrderCount" slot-scope="text, row">
          {{row.surplusPreOrderCount+row.temporaryRemainingQuota}}<br/>
          <span class="enzosp"><a @click="checkQuotaLog(row,1)">(固定：{{row.surplusPreOrderCount}} )</a></span><br/>
          <span class="enzosp"><a @click="checkQuotaLog(row,2)">(临时：{{row.temporaryRemainingQuota}} )</a></span>
        </span>
      </a-table>
      <!-- 申请配额 -->
      <DealerPreOrdersNum-edit-modal
        ref="DealerPreOrdersNumEditModal"
        @reload="getData"
      ></DealerPreOrdersNum-edit-modal>
      <!-- 分配配额 -->
      <AllocationRuota ref="AllocationRuota" @reload="getData"></AllocationRuota>
    </div>

    <a-modal title="查看配额使用日志" :maskClosable="false" v-model="visible" width="60%" :footer="null">
      <a-form-model :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }">
        <a-row :gutter="4">
          <a-col :md="8">
            <a-form-model-item label="收支类型">
               <a-select v-model="quotaSpendingStatus" placeholder="收支类型">
                  <a-select-option value="1">支出</a-select-option>
                  <a-select-option value="2">收入</a-select-option>
                  <a-select-option value="3">系统增额</a-select-option>
                  <a-select-option value="4">系统减额</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8">
            <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
              <a-button @click="getCheck" type="primary" icon="search">查询</a-button>
              <a-button @click="restCheck" type="default" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-table
        :rowKey="(record) => record.id"
        :columns="columnsCheck"
        :data-source="tableDataCheck"
        bordered
        :pagination="page1"
        @change="changeTableCheck"
      >
        <span slot="quotaType" slot-scope="text">
          <span v-if="text == 1">固定</span>
          <span v-if="text == 2">临时</span>
        </span>
        <span slot="quotaSpendingStatus" slot-scope="text">
          <span v-if="text == 1">支出</span>
          <span v-if="text == 2">收入</span>
          <span v-if="text == 3">系统增额</span>
          <span v-if="text == 4">系统减额</span>
        </span>
      </a-table>
    </a-modal>

  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerPreOrdersNumEditModal from './components/DealerPreOrdersNumEditModal.vue'
import AllocationRuota from './components/AllocationRuota.vue'

import { storeAllocationQuota } from './api/DealerPreOrdersNumApi'
import {checkPermission} from '@/utils/permissions'

export default {
  name: 'dealerPreOrdersNum',
  components: {
    DealerPreOrdersNumEditModal,
    AllocationRuota,
  },
  data() {
    return {
      columns: [
        {
          title: '门店',
          dataIndex: 'storeName',
          key: 'storeName',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '员工',
          dataIndex: 'storeStaffName',
          key: 'storeStaffName',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '固定',
          dataIndex: 'orderCount',
          key: 'orderCount',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '临时',
          dataIndex: 'temporaryQuota',
          key: 'temporaryQuota',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '剩余数量',
          dataIndex: 'surplusPreOrderCount',
          key: 'surplusPreOrderCount',
          /*width:150,*/
          align: 'center',
          // ellipsis: true,
          scopedSlots: { customRender: 'surplusPreOrderCount'}
        },
      ],
      storeData: [],
      managerData: [],
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      page1: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page1.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isUpdate: checkPermission('store:quota:update'),
      visible: false,
      tableDataCheck:[],
      rowQuotaLog:{},
      quotaSpendingStatus:'',
      typeQuotaLog:1,
      columnsCheck: [
        // {
        //   title: '配额类型',
        //   dataIndex: 'quotaType',
        //   key: 'quotaType',
        //   /*width:150,*/
        //   align: 'center',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'quotaType' },
        // },
        {
          title: '配额收支类型',
          dataIndex: 'quotaSpendingStatus',
          key: 'quotaSpendingStatus',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'quotaSpendingStatus' },
        },
        {
          title: '日志',
          dataIndex: 'reason',
          key: 'reason',
          width:350,
          align: 'center',
          // ellipsis: true,
        },
        // {
        //   title: '配额数',
        //   dataIndex: 'num',
        //   key: 'num',
        //   /*width:150,*/
        //   align: 'center',
        //   ellipsis: true,
        // },
        {
          title: '创建人',
          dataIndex: 'createUser',
          key: 'createUser',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '使用时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width:180,
          align: 'center',
          ellipsis: true,
        },
      ],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },

  filters: {
    status(type) {
      const typeMap = {
        0: '草稿',
        1: '待审核',
        2: '已审核待分配',
        3: '审核拒绝',
      }
      return typeMap[type]
    },
  },

  methods: {
    // 查看配额使用日志
    checkQuotaLog(row,type) {
      this.rowQuotaLog =row
      this.typeQuotaLog = type
      this.visible = true
      let params = {
        storeStaffId: row.storeStaffId,
        quotaType: type,
         pageNumber: this.page1.current,
        pageSize: this.page1.pageSize,
        quotaSpendingStatus:this.quotaSpendingStatus
      }
      this.axios.get('/api/base/quota/storeStaffQuotaLog/list', { params: params }).then((res) => {
          this.tableDataCheck=res.body.records || []
          this.page1.total = res.body.total
      })
    },
    changeTableCheck(pagination){
      this.page1 = pagination
      this.checkQuotaLog(this.rowQuotaLog,this.typeQuotaLog)
    },
    getCheck(){
      this.checkQuotaLog(this.rowQuotaLog,this.typeQuotaLog)
    },
    restCheck(){
      this.quotaSpendingStatus= ''
      this.page1.pageSize = 10
      this.page1.current = 1
      this.checkQuotaLog(this.rowQuotaLog,this.typeQuotaLog)
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRows = []
      this.selectedRowKeys = []
      storeAllocationQuota({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      //获取门店
      this.axios.get(`/api/base/store/dealerStore/storeListForDealer`).then((res) => {
        if (res.code == 200) {
          this.storeData = res.body
        }
      })

      //获取员工
      this.axios.get(`/api/base/system/dealer/manager/managerListForDealer`).then((res) => {
        if (res.code == 200) {
          this.managerData = res.body
        }
      })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerPreOrdersNumEditModal.setRowData({}, 'add')
        return
      }

      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerPreOrdersNum(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'submit_audio':
          _this.$confirm({
            title: '确认提交审核？',
            onOk: () => {
              _this.axios
                .get(`/api/base/customer/dealerTemporaryQuotaRecord/submitReview/${_this.selectedRowKeys[0]}`)
                .then((res) => {
                  _this.$message.success(res.message)
                  _this.getData()
                })
                .catch((err) => {})
            },
          })
          break
        case 'staff_allocation':
          _this.$refs.AllocationRuota.setRowData(_this.selectedRows[0], 'staff_allocation')
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.enzosp{
  color: #b9b9b9;
  font-size: 12px;
}
</style>
